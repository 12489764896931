import { useAuth } from '@/app/_auth/auth-hooks'
import { AuthStep } from '@/app/_auth/auth-step-navigator'
import { AuthStepTemplate } from '@/app/_auth/steps/_common/auth-step-template'
import { useRegistrationForm } from '@/app/_auth/steps/_common/registration-form-context'
import { Button } from '@/components/ui/button'
import { CharacterCount, Label } from '@/components/ui/label'
import { Textarea } from '@/components/ui/textarea'
import { GA_EVENTS, trimAndValidate } from '@/lib/utils'
import { T, useTranslate } from '@tolgee/react'
import { Field, Form } from 'react-final-form'
import ReactGA from 'react-ga4'

export type RegisterPurposeForm = {
  purpose: string
}
type RegisterPurposeFormErrors = Partial<Record<keyof RegisterPurposeForm, boolean | string>>

export function RegisterPurposeStep() {
  const { t } = useTranslate()
  const { setPurposeValues, purposeValues } = useRegistrationForm()
  const { setStep } = useAuth()

  const submit = (initialValues: RegisterPurposeForm) => {
    const { values, errors } = trimAndValidate({ initialValues, validate })

    if (errors) return errors

    ReactGA.event({
      action: GA_EVENTS.ACTION.BTN,
      category: GA_EVENTS.CATEGORY.ACC,
      label: 'submit_purpose_registration_form',
    })

    setPurposeValues(values)
    setStep(AuthStep.RegisterConsent)
  }

  const back = (values: RegisterPurposeForm) => {
    setPurposeValues(values)
    setStep(AuthStep.RegisterOrg)
  }

  return (
    <AuthStepTemplate>
      <Form<RegisterPurposeForm>
        onSubmit={submit}
        initialValues={purposeValues ?? { purpose: '' }}
        validate={validate}
      >
        {({ handleSubmit, values, valid }) => (
          <form onSubmit={handleSubmit} className='flex flex-col gap-8'>
            <AuthStepTemplate.Header>
              <Label htmlFor='purpose' variant='registration-title'>
                <T keyName='auth.steps.usage.title' />
              </Label>
            </AuthStepTemplate.Header>
            <div className='w-full'>
              <Field name='purpose'>
                {({ input, meta }) => (
                  <>
                    <Textarea
                      autoFocus
                      className='min-h-24'
                      placeholder={t('auth.steps.usage.form.purpose.placeholder')}
                      maxLength={MAX_PURPOSE_CHARS}
                      variant={meta.submitFailed && meta.invalid ? 'inputError' : 'input'}
                      {...input}
                    />
                    <CharacterCount length={input.value.length} max={MAX_PURPOSE_CHARS} />
                  </>
                )}
              </Field>

              <AuthStepTemplate.ButtonRow className='pt-5'>
                <Button
                  onClick={() => back(values)}
                  variant='square-outline'
                  className='w-full'
                  size='sm'
                >
                  <T keyName='auth.steps.common.buttons.back' />
                </Button>
                <Button
                  type='submit'
                  disabled={!valid}
                  variant='square'
                  size='sm'
                  className='w-full'
                >
                  <T keyName='auth.steps.common.buttons.next' />
                </Button>
              </AuthStepTemplate.ButtonRow>
            </div>
          </form>
        )}
      </Form>
      <AuthStepTemplate.Footer />
    </AuthStepTemplate>
  )
}

// 👇 form validation

const MAX_PURPOSE_CHARS = 255

function validate({ purpose }: RegisterPurposeForm): RegisterPurposeFormErrors {
  const errors: RegisterPurposeFormErrors = {}

  if (!purpose) errors.purpose = true
  else if (purpose.length > MAX_PURPOSE_CHARS) {
    errors.purpose = `Character limit of ${MAX_PURPOSE_CHARS} exceeded`
  }

  return errors
}
