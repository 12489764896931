import { auth } from '@/app/_auth/auth'
import { RelativePath } from '@/hooks/api/_paths'
import { isVPCMode } from '@/lib/utils'

/**
 * Makes a request to the specified API with the given path and options.
 *
 * @param {string} options.path - The relative URL path to query.
 * @param {ApiType} options.api - The API to target, 'users' or 'task-manager'.
 * @param {RequestInit} [options.options] - Additional options to pass to the fetch call.
 * @returns {Promise<Response>} The response from the API.
 */
async function handleRequest(path: RelativePath, options: RequestInit = {}): Promise<Response> {
  try {
    const { absoluteUrl, token, clientId } = await getRequestData(path)

    const requestOptions: RequestInit = {
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-Client-Id': clientId,
        ...options.headers,
      },
      ...options,
    }

    const response = await fetch(absoluteUrl, requestOptions)

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

async function getRequestData(
  path: string,
): Promise<{ token: string | undefined; clientId: string; absoluteUrl: URL }> {
  let token: string | undefined
  let clientId: string

  if (isVPCMode) {
    clientId = import.meta.env.VITE_CLIENT_ID
  } else {
    await auth.authStateReady()

    if (!auth.currentUser) throw new Error('LOGGED_IN_USER_NOT_FOUND')

    token = await auth.currentUser.getIdToken()

    if (!token) throw new Error('FAILED_TO_GET_ACCESS_TOKEN')

    clientId = auth.currentUser.uid
  }

  if (!clientId) throw new Error('FAILED_TO_GET_CLIENT_ID')

  const apiBaseUrl = import.meta.env.VITE_API_BASE_URL

  if (!apiBaseUrl) throw new Error('API_BASE_URL_ENV_VAR_NOT_FOUND')

  const absoluteUrl = new URL(path, apiBaseUrl)

  return { absoluteUrl, token, clientId }
}

export { handleRequest }
