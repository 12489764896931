import { useAuth, useOnAuthStateChanged } from '@/app/_auth/auth-hooks'
import { AuthStepTemplate } from '@/app/_auth/steps/_common/auth-step-template'
import { EmergenceAnimation } from '@/components/ui/emergence-loader'
import { useEmergenceUserAndStepQuery } from '@/hooks/api/use-emergence-user-and-step-query'
import { useTranslate } from '@tolgee/react'

export function GetEmergenceUserStep() {
  const { t } = useTranslate()
  const { setStep, setUser } = useAuth()
  const { mutateAsync } = useEmergenceUserAndStepQuery()

  useOnAuthStateChanged({
    onLogin: () => {
      mutateAsync().then(({ user, nextStep }) => {
        setUser(user)
        setStep(nextStep)
      })
    },
  })

  return (
    <AuthStepTemplate hideLogo>
      <EmergenceAnimation title={t('auth.steps.loading.a11y')} />
    </AuthStepTemplate>
  )
}
