export const HistoryIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='5' cy='7' r='1.5' stroke='#827C8C' />
    <circle cx='5' cy='12' r='1.5' stroke='#827C8C' />
    <circle cx='5' cy='17' r='1.5' stroke='#827C8C' />
    <rect x='9.5' y='5.75' width='11' height='2.5' rx='0.5' stroke='#827C8C' />
    <rect x='9.5' y='10.75' width='11' height='2.5' rx='0.5' stroke='#827C8C' />
    <rect x='9.5' y='15.75' width='11' height='2.5' rx='0.5' stroke='#827C8C' />
  </svg>
)
