import { auth } from '@/app/_auth/auth'
import type { RegistrationForm } from '@/app/_auth/steps/_common/registration-form-context'
import type { ConsentSettingsPolicyData } from '@/hooks/api/_types'
import { handleRequest } from '@/lib/http-utils'
import { isVPCMode } from '@/lib/utils'
import { useMutation } from '@tanstack/react-query'
import { apiPaths } from './_paths'

type SaveRegistrationRequestBody = {
  name: string
  email: string
  organization: string
  role: string
  org_size: string
  industry: string
  purpose: string
} & ConsentSettingsPolicyData

export function useSaveRegistrationMutation() {
  return useMutation({
    retry: 3,
    retryDelay: 250,
    mutationKey: ['register-user'],
    mutationFn: async (data: RegistrationForm) => {
      const response = await handleRequest(apiPaths.users.register, {
        method: 'POST',
        body: JSON.stringify(port(data)),
      })

      if (response.status === 400) {
        throw new Error('INVALID_REGISTRATION_REQUEST_BODY')
      }

      if (response.status !== 200) {
        throw new Error('FAILED_TO_REGISTER_USER')
      }
    },
  })
}

function port(data: RegistrationForm): SaveRegistrationRequestBody {
  const email = isVPCMode ? import.meta.env.VITE_CLIENT_EMAIL : auth.currentUser?.email

  if (!email) throw new Error('USER_EMAIL_NOT_FOUND')

  return {
    email,
    name: data.fullName,
    organization: data.orgName,
    role: data.role,
    org_size: data.orgSize.trim(),
    industry: data.otherIndustry?.trim() || data.industry.trim(),
    purpose: data.purpose,
    policies: [
      { name: 'ALLOW_DATA', preference: data.allowData },
      { name: 'ALLOW_MARKETING_COMMS', preference: data.allowMarketing },
    ],
  }
}
