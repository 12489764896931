export const LogoutIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    {...props}
  >
    <path d='M11.5 6H18.5V18H11.5' stroke='#827C8C' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M15.5 12H5.5' stroke='#827C8C' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M8.5 9L5.5 12L8.5 15' stroke='#827C8C' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
