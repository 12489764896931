/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'

// Create Virtual Routes

const HistoryLazyImport = createFileRoute('/history')()
const AccountLazyImport = createFileRoute('/account')()
const IndexLazyImport = createFileRoute('/')()
const ChatsWorkflowIdLazyImport = createFileRoute('/chats/$workflowId')()

// Create/Update Routes

const HistoryLazyRoute = HistoryLazyImport.update({
  id: '/history',
  path: '/history',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/history.lazy').then((d) => d.Route))

const AccountLazyRoute = AccountLazyImport.update({
  id: '/account',
  path: '/account',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/account.lazy').then((d) => d.Route))

const IndexLazyRoute = IndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/index.lazy').then((d) => d.Route))

const ChatsWorkflowIdLazyRoute = ChatsWorkflowIdLazyImport.update({
  id: '/chats/$workflowId',
  path: '/chats/$workflowId',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/chats.$workflowId.lazy').then((d) => d.Route),
)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/account': {
      id: '/account'
      path: '/account'
      fullPath: '/account'
      preLoaderRoute: typeof AccountLazyImport
      parentRoute: typeof rootRoute
    }
    '/history': {
      id: '/history'
      path: '/history'
      fullPath: '/history'
      preLoaderRoute: typeof HistoryLazyImport
      parentRoute: typeof rootRoute
    }
    '/chats/$workflowId': {
      id: '/chats/$workflowId'
      path: '/chats/$workflowId'
      fullPath: '/chats/$workflowId'
      preLoaderRoute: typeof ChatsWorkflowIdLazyImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexLazyRoute
  '/account': typeof AccountLazyRoute
  '/history': typeof HistoryLazyRoute
  '/chats/$workflowId': typeof ChatsWorkflowIdLazyRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexLazyRoute
  '/account': typeof AccountLazyRoute
  '/history': typeof HistoryLazyRoute
  '/chats/$workflowId': typeof ChatsWorkflowIdLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexLazyRoute
  '/account': typeof AccountLazyRoute
  '/history': typeof HistoryLazyRoute
  '/chats/$workflowId': typeof ChatsWorkflowIdLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths: '/' | '/account' | '/history' | '/chats/$workflowId'
  fileRoutesByTo: FileRoutesByTo
  to: '/' | '/account' | '/history' | '/chats/$workflowId'
  id: '__root__' | '/' | '/account' | '/history' | '/chats/$workflowId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexLazyRoute: typeof IndexLazyRoute
  AccountLazyRoute: typeof AccountLazyRoute
  HistoryLazyRoute: typeof HistoryLazyRoute
  ChatsWorkflowIdLazyRoute: typeof ChatsWorkflowIdLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexLazyRoute: IndexLazyRoute,
  AccountLazyRoute: AccountLazyRoute,
  HistoryLazyRoute: HistoryLazyRoute,
  ChatsWorkflowIdLazyRoute: ChatsWorkflowIdLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/account",
        "/history",
        "/chats/$workflowId"
      ]
    },
    "/": {
      "filePath": "index.lazy.tsx"
    },
    "/account": {
      "filePath": "account.lazy.tsx"
    },
    "/history": {
      "filePath": "history.lazy.tsx"
    },
    "/chats/$workflowId": {
      "filePath": "chats.$workflowId.lazy.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
