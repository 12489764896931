import { auth } from '@/app/_auth/auth'
import { useAuth } from '@/app/_auth/auth-hooks'
import { EmergenceIcon } from '@/components/icons/emergence-icon'
import { HistoryIcon } from '@/components/icons/history-icon'
import { LogoutIcon } from '@/components/icons/logout-icon'
import { NavExpandIcon } from '@/components/icons/nav-expand-icon'
import { SettingsIcon } from '@/components/icons/settings-icon'
import { Avatar } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { useAllWorkflowsQuery } from '@/hooks/api/use-all-workflows-query'
import { cn, GA_EVENTS, isVPCMode } from '@/lib/utils'
import { Link, LinkComponentProps } from '@tanstack/react-router'
import { T, useTranslate } from '@tolgee/react'
import { cva } from 'class-variance-authority'
import { signOut } from 'firebase/auth'
import { ChevronUp, PlusIcon } from 'lucide-react'
import React from 'react'
import ReactGA from 'react-ga4'
import useScript from 'react-script-hook'

export function SideNavigation() {
  const { t } = useTranslate()
  const { user, domain } = useAuth()
  const [isExpanded, setIsExpanded] = React.useState(true)
  const allChatsQuery = useAllWorkflowsQuery({
    searchParams: { limit: 5 },
  })
  const pinnedChatsQuery = useAllWorkflowsQuery({
    searchParams: {
      pinned_worflows_only: true,
      limit: 5,
    },
  })

  useScript({
    src: import.meta.env.VITE_TERMLY_SCRIPT_SRC,
    checkForExisting: true,
    defer: true,
  })

  return (
    <article className='flex h-full max-h-full bg-[#F9F7F7]'>
      <picture>
        <source srcSet='/public/img/side-nav-bg.webp' type='image/webp' />
        <source srcSet='/public/img/side-nav-bg.jpg' type='image/jpeg' />
        <img
          src='/public/img/side-nav-bg.jpg'
          className='h-min min-h-full min-w-3'
          alt='Side navigation background graphic'
          width={12}
        />
      </picture>
      <div
        className={cn(
          'bg-sidebar-links flex flex-col items-center gap-2 px-4 pt-6 transition-[width] ease-out',
          isExpanded ? 'w-[275px]' : 'w-[76px]',
        )}
      >
        <div className='flex w-full items-center justify-between'>
          {isExpanded && (
            <a
              rel='noreferrer'
              href={import.meta.env.VITE_EMERGENCE_HOMEPAGE}
              target='_blank'
              className='h-[44px] shrink-0 self-start rounded p-2.5 outline-none'
              data-gtmid='navbar-logo-link'
              title={t('navigation.logoTitle')}
            >
              <EmergenceIcon />
            </a>
          )}
          <Tooltip delayDuration={0}>
            <TooltipTrigger asChild>
              <Button
                unstyled
                aria-label={t('navigation.pinTitle')}
                className='flex h-11 w-11 items-center justify-center rounded hover:bg-white/40 focus:bg-white/40'
                onClick={() => setIsExpanded((prev) => !prev)}
              >
                <NavExpandIcon className='shrink-0' />
              </Button>
            </TooltipTrigger>
            <TooltipContent side='right' align='center' sideOffset={8}>
              {isExpanded ? 'Hide sidebar' : 'Show sidebar'}
            </TooltipContent>
          </Tooltip>
        </div>
        <nav className='flex w-full flex-col items-center gap-2 overflow-hidden pt-1'>
          <NavLink
            to='/'
            hideTooltip={isExpanded}
            className='shrink-0 justify-center overflow-hidden text-[#252539] outline outline-1 -outline-offset-1 outline-white hover:outline-none focus:outline-none active:outline-none [&.active]:bg-transparent [&.active]:hover:bg-[#EEF1F4]'
            tooltip={t('navigation.newChat.title')}
            title={t('navigation.newChat.a11y')}
            onClick={() => {
              ReactGA.event({
                action: GA_EVENTS.ACTION.BTN,
                category: GA_EVENTS.CATEGORY.NAV,
                label: 'navigate_home',
              })
            }}
          >
            {isExpanded && (
              <span className='shrink-0 whitespace-nowrap font-circular-xx text-xs uppercase tracking-wide'>
                <T keyName='navigation.newChat.title' />
              </span>
            )}
            <PlusIcon
              strokeWidth={2}
              strokeLinecap='square'
              className='h-[14px] w-[14px] shrink-0'
            />
          </NavLink>
          <NavLink
            to='/history'
            hideTooltip={isExpanded}
            tooltip={t('navigation.history.title')}
            title={t('navigation.history.a11y')}
            className='mb-4'
            onClick={() => {
              ReactGA.event({
                action: GA_EVENTS.ACTION.BTN,
                category: GA_EVENTS.CATEGORY.NAV,
                label: 'navigate_history',
              })
            }}
          >
            <HistoryIcon className='shrink-0' />
            {isExpanded && (
              <span className='relative top-[1px] whitespace-nowrap'>
                <T keyName='navigation.history.title' />
              </span>
            )}
          </NavLink>
          {pinnedChatsQuery.data?.data && pinnedChatsQuery.data?.data.length > 0 && (
            <ChatHistoryList title='Pinned chats' className={cn(isExpanded ? 'w-full' : 'w-0')}>
              {pinnedChatsQuery.data?.data.map(({ workflowId, title }) => (
                <NavLink
                  key={workflowId}
                  className='block h-10 w-full shrink-0 truncate pl-4 pr-2 font-circular-xx text-sm font-light'
                  to='/chats/$workflowId'
                  resetScroll={false}
                  params={{ workflowId }}
                  tooltip={title}
                  delayDuration={700}
                >
                  {title}
                </NavLink>
              ))}
            </ChatHistoryList>
          )}
          {allChatsQuery.data?.data && allChatsQuery.data?.data.length > 0 && (
            <ChatHistoryList
              title='Latest chats'
              defaultExpanded
              className={cn(isExpanded ? 'w-full' : 'w-0')}
            >
              {allChatsQuery.data?.data.map(({ workflowId, title }) => (
                <NavLink
                  key={workflowId}
                  className='block h-10 w-full shrink-0 truncate pl-4 pr-2 font-circular-xx text-sm font-light'
                  to='/chats/$workflowId'
                  resetScroll={false}
                  params={{ workflowId }}
                  tooltip={title}
                  delayDuration={700}
                >
                  {title}
                </NavLink>
              ))}
            </ChatHistoryList>
          )}
        </nav>
        <footer className='mt-auto flex w-full flex-col gap-3 py-4'>
          <button
            tabIndex={0}
            title={t('account.userProfile.a11y')}
            className='group/profile -mx-1.5 box-content flex flex-col gap-1 rounded-lg p-1.5 outline-none outline outline-1 outline-offset-0 focus-within:bg-[rgba(255,255,255,.2)] focus-within:outline-white hover:bg-[rgba(255,255,255,.2)] hover:outline-white'
          >
            <div className='invisible flex max-h-0 w-full flex-col gap-1 overflow-clip transition-all group-focus-within/profile:visible group-focus-within/profile:max-h-80 group-hover/profile:visible group-hover/profile:max-h-80'>
              <NavLink
                to='/account'
                hideTooltip={isExpanded}
                tooltip={t('navigation.account.title')}
                title={t('navigation.account.a11y')}
                className='hover:bg-white/40 focus:bg-white/40 [&.active]:bg-white/60'
              >
                <SettingsIcon className='shrink-0' />
                {isExpanded && (
                  <span className='relative top-[1px]'>
                    <T keyName='navigation.account.title' />
                  </span>
                )}
              </NavLink>
              {!isVPCMode && (
                <NavAnchor
                  href='#'
                  hideTooltip={isExpanded}
                  onClick={() => signOut(auth)}
                  tooltip={t('navigation.logout.title')}
                  title={t('navigation.logout.a11y')}
                  className='hover:bg-white/40 focus:bg-white/40'
                >
                  <LogoutIcon className='shrink-0' />
                  {isExpanded && (
                    <span className='relative top-[1px]'>
                      <T keyName='navigation.logout.title' />
                    </span>
                  )}
                </NavAnchor>
              )}
            </div>
            <div className='relative flex h-10 cursor-default items-center gap-4 px-3'>
              <Avatar size='sm' />
              {user?.email && isExpanded && (
                <span className='overflow-hidden overflow-ellipsis font-neue-machina text-xs font-normal'>
                  {user.email}
                </span>
              )}
            </div>
          </button>
          <span
            className={cn(
              'whitespace-nowrap text-sm italic text-black/50',
              !isExpanded && 'opacity-0',
            )}
          >
            Domain: {domain}
          </span>
          <div className='h-11 overflow-hidden'>
            {isExpanded && (
              <div className='flex min-w-[275px] flex-wrap gap-2'>
                <FooterAnchor
                  href={import.meta.env.VITE_PRIVACY_POLICY_URL}
                  target='_blank'
                  rel='noreferrer'
                  title={t('navigation.privacyPolicy.a11y')}
                >
                  <T keyName='navigation.privacyPolicy.title' />
                </FooterAnchor>
                <FooterAnchor
                  href={import.meta.env.VITE_TERMS_OF_SERVICE_URL}
                  target='_blank'
                  rel='noreferrer'
                  title={t('navigation.terms.a11y')}
                >
                  <T keyName='navigation.terms.title' />
                </FooterAnchor>
                <FooterAnchor
                  href='#'
                  className='termly-display-preferences'
                  title={t('navigation.cookie.a11y')}
                >
                  <T keyName='navigation.cookie.title' />
                </FooterAnchor>
              </div>
            )}
          </div>
        </footer>
      </div>
    </article>
  )
}

// 👇 nav components

const navItemClasses = cva(
  'flex items-center whitespace-nowrap gap-2 h-10 w-full cursor-pointer select-none rounded p-2.5 font-neue-machina text-sm font-normal text-primary outline-none hover:bg-[#EEF1F4] focus:bg-[#EEF1F4] [&.active]:bg-[#F5F5F7] [&.active]:focus:bg-[#F5F5F7]',
)

type NavItemProps<T> = ComponentProps<
  {
    tooltip?: string | null
    delayDuration?: number
    hideTooltip?: boolean
  } & T
>

const NavLink = ({
  tooltip,
  className,
  children,
  hideTooltip,
  delayDuration = 0,
  ...props
}: NavItemProps<LinkComponentProps>) => (
  <Tooltip delayDuration={delayDuration}>
    <TooltipTrigger asChild>
      <Link
        className={cn(navItemClasses(), className)}
        {...(tooltip && { 'aria-label': tooltip })}
        {...props}
      >
        {children}
      </Link>
    </TooltipTrigger>
    <TooltipContent
      side='right'
      align='center'
      sideOffset={8}
      className={cn('h-fit max-w-80 whitespace-pre-line', (!tooltip || hideTooltip) && 'hidden')}
    >
      {tooltip}
    </TooltipContent>
  </Tooltip>
)

const NavAnchor = ({
  tooltip,
  className,
  children,
  delayDuration,
  hideTooltip,
  ...props
}: NavItemProps<React.AnchorHTMLAttributes<HTMLAnchorElement>>) => (
  <Tooltip delayDuration={delayDuration}>
    <TooltipTrigger asChild>
      <a
        className={cn(navItemClasses(), className)}
        {...(tooltip && { 'aria-label': tooltip })}
        {...props}
      >
        {children}
      </a>
    </TooltipTrigger>
    <TooltipContent
      side='right'
      align='center'
      sideOffset={8}
      className={cn((!tooltip || hideTooltip) && 'hidden')}
    >
      {tooltip}
    </TooltipContent>
  </Tooltip>
)

const FooterAnchor = ({
  children,
  className,
  ...props
}: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
  <a
    className={cn(
      'flex w-fit cursor-pointer items-start whitespace-nowrap rounded-sm py-[1px] text-xs font-light text-primary underline outline-none ring-focus focus:ring',
      className,
    )}
    {...props}
  >
    {children}
  </a>
)

function ChatHistoryList({
  className,
  children,
  title,
  defaultExpanded = false,
}: ComponentProps<{ title: string; defaultExpanded?: boolean }>) {
  const [expanded, setExpanded] = React.useState(defaultExpanded)
  return (
    <div className={cn('overflow-hidden', className)}>
      <Button
        unstyled
        className='flex items-center gap-2 overflow-hidden whitespace-nowrap px-2 py-1.5 font-neue-machina text-sm text-[#827C8C]'
        onClick={() => setExpanded((prev) => !prev)}
        title={expanded ? 'Collapse' : 'Expand'}
      >
        {title}
        <ChevronUp
          className={cn('h-4 w-4 shrink-0 transition-transform', expanded && 'rotate-180')}
        />
      </Button>
      <div
        className={cn(
          'flex w-full max-w-full flex-col gap-1 overflow-hidden transition-[max-height,opacity] ease-in-out',
          expanded ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0',
        )}
      >
        {children}
      </div>
    </div>
  )
}
