import { useOnAuthStateChanged } from '@/app/_auth/auth-hooks'
import { AuthGuard, AuthStep } from '@/app/_auth/auth-step-navigator'
import { AuthProviderID } from '@/app/_auth/steps/_common/login-buttons'
import type { EmergenceUser } from '@/hooks/api/use-emergence-user-and-step-query'
import type { FirebaseError } from 'firebase/app'
import React from 'react'

export const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const [step, setStep] = React.useState<AuthContextValue['step']>(AuthStep.GetEmergenceUser)
  const [user, setUser] = React.useState<AuthContextValue['user']>(null)
  const [authError, setAuthError] = React.useState<AuthContextValue['authError']>(null)
  const [domain, setDomain] = React.useState<Domain>(Domain.Orchestrator)

  const value = React.useMemo(
    () => ({
      step,
      setStep,
      user,
      setUser,
      authError,
      setAuthError,
      domain,
      setDomain,
    }),
    [step, user, authError, domain],
  )

  useOnAuthStateChanged({
    onLogin: () => setStep(AuthStep.GetEmergenceUser),
    onLogout: () => {
      setUser(null)

      if (step !== AuthStep.Create && step !== AuthStep.Login) {
        setStep(AuthStep.Login)
      }
    },
  })

  return (
    <AuthContextProvider value={value}>
      <AuthGuard step={step}>{children}</AuthGuard>
    </AuthContextProvider>
  )
}

// 👇 auth context

export const AuthContextProvider = React.memo(
  ({ children, value }: React.PropsWithChildren<{ value: AuthContextValue }>) => (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  ),
)

interface AuthError extends FirebaseError {
  customData?: FirebaseError['customData'] & {
    email?: string
    _tokenResponse?: { providerId?: AuthProviderID; verifiedProvider?: AuthProviderID[] }
  }
}

export enum Domain {
  Orchestrator = 'em-orchestrator',
  AKM = 'em-akm',
  Compliance = 'em-compliance',
  GeneralTextModeration = 'em-general-text-moderation',
  ChildTextModeration = 'em-child-text-moderation',
  WebAutomation = 'em-web-automation',
}

export type AuthContextValue = {
  step: AuthStep | null
  setStep: SetState<AuthStep | null>
  user: EmergenceUser | null
  setUser: SetState<EmergenceUser | null>
  authError: AuthError | null
  setAuthError: SetState<AuthError | null>
  domain: Domain
  setDomain: SetState<Domain>
}

export const AuthContext = React.createContext<AuthContextValue>({
  user: null,
  step: AuthStep.GetEmergenceUser,
  setStep: () => {
    throw new Error('AuthContext.setStep default value used.')
  },
  setUser: () => {
    throw new Error('AuthContext.setUser default value used.')
  },
  authError: null,
  setAuthError: () => {
    throw new Error('AuthContext.setAuthError default value used.')
  },
  domain: Domain.Orchestrator,
  setDomain: () => {
    throw new Error('AuthContext.setDomain default value used.')
  },
})
