import { Domain } from '@/app/_auth/auth-provider'

type RelativePath = `/${string}`

const USERS_BASE_PATH: RelativePath = '/api/v0/users'
const tmBasePath: (domain: Domain) => RelativePath = (domain) => `/v0/orchestrators/${domain}`

type TMPathProps<T = {}> = { domain: Domain } & T
type TMWorkflowPathProps<T = {}> = TMPathProps<{ workflowId: string }> & T
const apiPaths = {
  taskManager: {
    workflows: ({ domain }: TMPathProps) => `${tmBasePath(domain)}/workflows`,
    examples: ({ domain }: TMPathProps) => `${tmBasePath(domain)}/workflows/examples`,
    workflowStatus: ({ domain, workflowId }: TMWorkflowPathProps) =>
      `${tmBasePath(domain)}/workflows/${workflowId}/status`,
    pinWorkflow: ({ domain, workflowId }: TMWorkflowPathProps) =>
      `${tmBasePath(domain)}/workflows/${workflowId}/pin`,
    unpinWorkflow: ({ domain, workflowId }: TMWorkflowPathProps) =>
      `${tmBasePath(domain)}/workflows/${workflowId}/unpin`,
    workflow: ({ domain, workflowId }: TMWorkflowPathProps) =>
      `${tmBasePath(domain)}/workflows/${workflowId}`,
    turnStatus: ({ domain, workflowId, turnId }: TMWorkflowPathProps<{ turnId: string }>) =>
      `${tmBasePath(domain)}/workflows/${workflowId}/turns/${turnId}/status`,
    cancelTurn: ({ domain, workflowId, turnId }: TMWorkflowPathProps<{ turnId: string }>) =>
      `${tmBasePath(domain)}/workflows/${workflowId}/turns/${turnId}/cancel`,
    messageFeedback: ({
      domain,
      workflowId,
      messageId,
    }: TMWorkflowPathProps<{ messageId: string }>) =>
      `${tmBasePath(domain)}/workflows/${workflowId}/messages/${messageId}/feedback`,
    workflowFeedback: ({ domain, workflowId }: TMWorkflowPathProps) =>
      `${tmBasePath(domain)}/workflows/${workflowId}/feedback`,
    workflowMessages: ({ domain, workflowId }: TMWorkflowPathProps) =>
      `${tmBasePath(domain)}/workflows/${workflowId}/messages`,
    workflowTitle: ({ domain, workflowId }: TMWorkflowPathProps) =>
      `${tmBasePath(domain)}/workflows/${workflowId}/title`,
  },
  users: {
    users: USERS_BASE_PATH,
    requestRuns: `${USERS_BASE_PATH}/requestRuns`,
    deactivateAccount: `${USERS_BASE_PATH}/deactivateAccount`,
    reactivateAccount: `${USERS_BASE_PATH}/reactivateAccount`,
    register: `${USERS_BASE_PATH}/register`,
    contact: `${USERS_BASE_PATH}/contact`,
    privacyPreferences: `${USERS_BASE_PATH}/privacyPreferences`,
    getApiKey: `${USERS_BASE_PATH}/getApiKey`,
    disableWelcomeMessage: `${USERS_BASE_PATH}/payment/disableWelcomeMessage`,
    rateLimit: `${USERS_BASE_PATH}/payment/rateLimit`,
  },
} satisfies Record<string, Record<string, RelativePath | ((params: any) => RelativePath)>>

export { apiPaths, type RelativePath }
