import { handleRequest } from '@/lib/http-utils'
import { useMutation } from '@tanstack/react-query'
import { apiPaths } from './_paths'

export function useReactivateAccountMutation() {
  return useMutation({
    mutationKey: ['reactivate-account'],
    mutationFn: async () => {
      const response = await handleRequest(apiPaths.users.reactivateAccount, { method: 'POST' })

      if (response.status !== 200) {
        throw new Error('FAILED_TO_REACTIVATE_ACCOUNT')
      }
    },
  })
}
