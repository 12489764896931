import { auth } from '@/app/_auth/auth'
import { AuthStep } from '@/app/_auth/auth-step-navigator'
import { toast } from '@/components/ui/toaster'
import { handleRequest } from '@/lib/http-utils'
import { isVPCMode } from '@/lib/utils'
import { useMutation } from '@tanstack/react-query'
import { signOut } from 'firebase/auth'
import { apiPaths } from './_paths'

type EmergenceUserQueryResponse = {
  nextStep: AuthStep | null
  user: EmergenceUser | null
}

type EmergenceUserAPIResponse = {
  name: string
  email: string
  organization: string
  role: string
  accountStatus: 'ACTIVE' | 'DEACTIVATED' | 'CLOSED'
  createdAt: string
  lastUpdatedAt: string
  tier?: string
  welcome_message: boolean
}

function useEmergenceUserAndStepQuery() {
  return useMutation({
    retry: 3,
    retryDelay: 250,
    mutationKey: ['get-emergence-user'],
    mutationFn: async (): Promise<EmergenceUserQueryResponse> => {
      try {
        const response = await handleRequest(apiPaths.users.users)

        if (response.status === 404) {
          return { nextStep: AuthStep.RegisterOrg, user: null }
        }

        if (response.status === 200) {
          const userData = (await response.json()) as EmergenceUserAPIResponse
          const emergenceUser = port(userData)

          if (emergenceUser.accountStatus === 'DEACTIVATED') {
            return { nextStep: AuthStep.Deactivated, user: emergenceUser }
          }

          return { nextStep: null, user: emergenceUser }
        }

        throw new Error('FAILED_TO_FETCH_EMERGENCE_USER_DATA')
      } catch {
        await signOut(auth)
        toast.error('Error fetching account information. Please login again.')
        return { nextStep: AuthStep.Create, user: null }
      }
    },
  })
}

type EmergenceUser = Omit<EmergenceUserAPIResponse, 'name'> & {
  uid: string
  tier: string
  firstName?: string
  lastName?: string
  fullName?: string
  photoURL?: string
}

function port({ name, tier, email, ...emUserData }: EmergenceUserAPIResponse): EmergenceUser {
  if (isVPCMode) {
    return {
      uid: import.meta.env.VITE_CLIENT_ID,
      tier: tier ?? 'Free',
      email: email || import.meta.env.VITE_CLIENT_EMAIL,
      ...emUserData,
    }
  }
  // check required fields
  if (!email) throw new Error('EMAIL_NOT_FOUND')
  if (!auth.currentUser?.uid) throw new Error('UID_NOT_FOUND')

  const emergenceUser: EmergenceUser = {
    uid: auth.currentUser.uid,
    email,
    tier: tier ?? 'Free',
    ...emUserData,
  }

  const fullName = name ?? auth.currentUser?.displayName
  // format name
  if (fullName) {
    emergenceUser.fullName = fullName

    const nameArr = fullName.split(' ')
    const firstName = nameArr[0]

    emergenceUser.firstName = firstName

    if (nameArr.length > 1) {
      const lastName = nameArr.at(-1)

      emergenceUser.lastName = lastName
    }
  }
  // get photo url
  if (auth.currentUser?.photoURL) {
    emergenceUser.photoURL = auth.currentUser.photoURL
  }

  return emergenceUser
}

export { useEmergenceUserAndStepQuery, type EmergenceUser }
