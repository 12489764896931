import { useAuth } from '@/app/_auth/auth-hooks'
import { apiPaths } from '@/hooks/api/_paths'
import type { Pagination } from '@/hooks/api/_types'
import { handleRequest } from '@/lib/http-utils'
import { formatDate } from '@/lib/utils'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

type Workflow = {
  workflowId: string
  title: string | null
  status: WorkflowStatus
  prompt: string
  startEpoch: number
  recentTurnStartTimeEpoch?: number | null
  clientId: string
  pinned?: boolean
  turnIds: string[] | null
  error?: {
    errorCode: string
    status: number
  } | null
}

enum WorkflowStatus {
  ValidationError = 'VALIDATION_ERROR',
  Queued = 'QUEUED',
  Planning = 'PLANNING',
  InProgress = 'IN_PROGRESS',
  Failure = 'FAILURE',
  Success = 'SUCCESS',
  Cancelled = 'CANCELLED',
}

type AllWorkflowsAPIResponse = {
  data: Workflow[]
  pagination: Pagination
}

type UseAllWorkflowsSearchParams = {
  sortOrder?: 'asc' | 'desc'
  sortBy?: 'title' | 'startEpoch' | 'recentTurnStartTimeEpoch' | 'status'
  page?: number
  limit?: number
  status?: Workflow['status']
  recentStartEpochFrom?: number
  recentStartEpochTo?: number
  pinned_worflows_only?: boolean
}

type UseAllWorkflowsQueryOptions = {
  searchParams?: UseAllWorkflowsSearchParams
  queryOptions?: Omit<UseQueryOptions<AllWorkflowsAPIResponse>, 'queryFn' | 'queryKey'>
}

function useAllWorkflowsQuery({
  searchParams = {},
  queryOptions = {},
}: UseAllWorkflowsQueryOptions = {}) {
  const { domain } = useAuth()
  const urlSearchString = new URLSearchParams(
    portSearchParams({
      page: 1,
      limit: 10,
      sortBy: 'recentTurnStartTimeEpoch',
      sortOrder: 'desc',
      ...searchParams,
    }),
  ).toString()

  return useQuery({
    queryKey:
      allWorkflowsKeyFactory[searchParams.pinned_worflows_only ? 'pinned' : 'all'](searchParams),
    queryFn: async () => {
      const response = await handleRequest(
        `${apiPaths.taskManager.workflows({ domain })}?${urlSearchString}`,
      )

      if (!response.ok) throw new Error('FAILED_TO_GET_WORKFLOWS')

      const json = (await response.json()) as AllWorkflowsAPIResponse

      return { ...json, data: json.data.map((w) => ({ ...w, title: parseWorkflowTitle(w) })) }
    },
    ...queryOptions,
  })
}

const portSearchParams = (
  params: UseAllWorkflowsSearchParams,
): Record<keyof UseAllWorkflowsSearchParams, string> =>
  Object.entries(params).reduce(
    (acc, [key, value]) => {
      if (typeof value === 'undefined' || value === null) {
        return acc
      }
      const valueString = `${value}`
      if (!valueString) return acc
      return { ...acc, [key]: valueString }
    },
    {} as Record<keyof UseAllWorkflowsSearchParams, string>,
  )

const MAX_WORKFLOW_TITLE_LENGTH = 200
const parseWorkflowTitle = ({
  title,
  prompt,
  recentTurnStartTimeEpoch,
  startEpoch,
}: Pick<Workflow, 'title' | 'prompt' | 'recentTurnStartTimeEpoch' | 'startEpoch'>) => {
  if (title) return title
  if (prompt.length > MAX_WORKFLOW_TITLE_LENGTH) return prompt.slice(0, MAX_WORKFLOW_TITLE_LENGTH)
  if (prompt) return prompt
  return `Workflow - ${formatDate(recentTurnStartTimeEpoch ?? startEpoch, { template: 'MM-DD-YYYY' })}`
}

const allWorkflowsKeyFactory = {
  all: (params?: UseAllWorkflowsSearchParams) =>
    ['all-workflows', ...(params ? [params] : [])] as const,
  pinned: (params?: UseAllWorkflowsSearchParams) =>
    [...allWorkflowsKeyFactory.all(), 'pinned-workflows', ...(params ? [params] : [])] as const,
}

function updateKeysInWorkflowAllCache({
  cachedData,
  workflowId,
  updateData,
}: {
  cachedData?: AllWorkflowsAPIResponse
  workflowId: string
  updateData: Partial<Workflow>
}) {
  if (!cachedData || !cachedData?.data || cachedData.data?.length === 0) {
    return cachedData
  }

  return {
    ...cachedData,
    data: cachedData.data.map((c) => {
      if (c.workflowId === workflowId) {
        return {
          ...c,
          ...updateData,
        }
      }
      return c
    }),
  }
}

const isWorkflowStatusLoading = (status?: Workflow['status']) => {
  if (!status) return false

  return [WorkflowStatus.InProgress, WorkflowStatus.Planning, WorkflowStatus.Queued].includes(
    status,
  )
}

export {
  allWorkflowsKeyFactory,
  isWorkflowStatusLoading,
  MAX_WORKFLOW_TITLE_LENGTH,
  parseWorkflowTitle,
  updateKeysInWorkflowAllCache,
  useAllWorkflowsQuery,
  WorkflowStatus,
  type AllWorkflowsAPIResponse,
  type UseAllWorkflowsSearchParams,
  type Workflow,
}
