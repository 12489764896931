import { EmergenceAnimation } from '@/components/ui/emergence-loader'
import { cn } from '@/lib/utils'
import { T } from '@tolgee/react'
import React from 'react'

function PageTemplate({ children }: React.PropsWithChildren) {
  return <div className='flex w-full flex-col overflow-y-auto bg-[#F9F7F7]'>{children}</div>
}

PageTemplate.Header = function PageTemplateHeader({ className, children }: ComponentProps) {
  return (
    <header
      className={cn(
        'flex h-[52px] shrink-0 items-center gap-2 border-b border-b-[#E1DEE2] px-4 lg:px-8 2xl:px-20',
        className,
      )}
    >
      {children}
    </header>
  )
}

PageTemplate.Content = function PageTemplateContent({ children, className }: ComponentProps) {
  return (
    <section
      className={cn(
        'flex h-full flex-col items-center gap-10 px-4 py-12 lg:px-8 2xl:px-20',
        className,
      )}
    >
      {children}
    </section>
  )
}

PageTemplate.H1 = function PageTemplateH1({
  className,
  children,
  variant = 'default',
  ...props
}: React.HTMLAttributes<HTMLHeadingElement> & { variant?: 'header' | 'default' }) {
  return (
    <h1
      className={cn(
        'font-neue-machina tracking-wide',
        variant === 'default' && 'text-[32px] font-bold leading-10',
        variant === 'header' && 'max-w-lg truncate whitespace-nowrap font-neue-machina font-normal',
        className,
      )}
      {...props}
    >
      {children}
    </h1>
  )
}

PageTemplate.H2 = function PageTemplateH2({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLHeadingElement>) {
  return (
    <h2 className={cn('font-neue-machina text-2xl font-bold tracking-wide', className)} {...props}>
      {children}
    </h2>
  )
}

PageTemplate.H3 = function PageTemplateH3({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLHeadingElement>) {
  return (
    <h3
      className={cn('font-neue-machina text-2xl font-normal tracking-wide', className)}
      {...props}
    >
      {children}
    </h3>
  )
}

PageTemplate.LoadingPage = function PageTemplateLoadingPage({ title }: { title: string }) {
  return (
    <div className='flex w-full items-center justify-center'>
      <EmergenceAnimation title={title} />
    </div>
  )
}

PageTemplate.ErrorPage = function PageTemplateErrorPage() {
  return (
    <div className='flex w-full items-center justify-center'>
      <p className='w-full text-center text-error-accent'>
        <T keyName='shared.error.system' />
      </p>
    </div>
  )
}

export { PageTemplate }
