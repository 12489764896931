import { cn } from '@/lib/utils'
import { cva, VariantProps } from 'class-variance-authority'
import { XIcon } from 'lucide-react'
import { toast as baseToast, ExternalToast, Toaster as Sonner } from 'sonner'

type ToasterProps = React.ComponentProps<typeof Sonner>

export const Toaster = (props: ToasterProps) => (
  <Sonner
    className='toaster group list-none md:w-[500px]'
    toastOptions={{ unstyled: true }}
    {...props}
  />
)

type ToastVariants = VariantProps<typeof toastVariants>
const toastVariants = cva(
  'flex shadow-lg sm:min-w-[360px] items-center text-sm font-[450] justify-between rounded border-l-8 px-4 py-3',
  {
    variants: {
      variant: {
        error: 'border-l-error-accent bg-error',
        warning: 'border-l-warning-accent bg-warning',
        success: 'border-l-success-accent bg-success',
      },
    },
  },
)

type ToastProps = { message: React.ReactNode; options?: ExternalToast }
type ToastMap = Record<
  NonNullable<ToastVariants['variant']>,
  (message: ToastProps['message'], options?: ToastProps['options']) => string | number
>

export const toast: ToastMap = {
  error: (message, options) => customToast({ variant: 'error', message, options }),
  warning: (message, options) => customToast({ variant: 'warning', message, options }),
  success: (message, options) => customToast({ variant: 'success', message, options }),
}

function customToast({ variant, message, options }: ToastVariants & ToastProps) {
  return baseToast.custom(
    (t) => (
      <div className={cn(toastVariants({ variant }), options?.className)}>
        {message}
        <button tabIndex={0} onClick={() => baseToast.dismiss(t)}>
          <XIcon className='w-4 text-[#827C8C]' />
        </button>
      </div>
    ),
    { ...options, className: cn(options?.className, 'right-0') },
  )
}
