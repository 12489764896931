import { cn } from '@/lib/utils'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { ButtonHTMLAttributes, forwardRef } from 'react'
import { Spinner } from './spinner'

export interface ButtonProps
  extends ButtonVariantProps,
    Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'disabled'> {
  asChild?: boolean
  unstyled?: boolean
}

export type ButtonVariantProps = VariantProps<typeof buttonVariants>

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant,
      loading,
      children,
      size,
      className,
      disabled,
      type = 'button',
      asChild,
      unstyled,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        ref={ref}
        tabIndex={0}
        type={type}
        disabled={!!disabled}
        className={cn(
          !unstyled &&
            buttonVariants({
              variant,
              size,
              disabled: !!disabled || loading,
              loading,
            }),
          className,
        )}
        {...props}
      >
        {loading ? (
          <>
            <span className='invisible flex gap-2'>{children}</span>
            <Spinner
              className='absolute m-auto'
              color={WHITE_SPINNER_VARIANTS.includes(variant) ? 'white' : 'black'}
              size={variant === 'square' ? 'xs' : 'sm'}
            />
          </>
        ) : (
          children
        )}
      </Comp>
    )
  },
)
Button.displayName = 'Button'

const WHITE_SPINNER_VARIANTS: ButtonVariantProps['variant'][] = [
  'square',
  'default',
  undefined,
  null,
]

export const buttonVariants = cva(
  'select-none flex items-center justify-center w-fit relative whitespace-nowrap gap-2 font-normal',
  {
    variants: {
      variant: {
        'outline-black':
          'rounded-full border-[#252539] border text-[#252539] bg-[#F9F7F7] text-sm focus:outline focus:outline-[4px] disabled:border-grey-300 focus:outline-grey-100 hover:bg-[#EEEEEF]',
        'outline-red':
          'rounded-full border-error-accent border text-error-accent bg-none text-sm focus:outline focus:outline-[4px] disabled:border-grey-300 focus:outline-grey-100 hover:bg-[#EEEEEF]',
        'outline-gray':
          'bg-[#D5D3D333] border cursor-pointer border-white rounded-full focus:outline shadow-md focus:shadow-none focus:outline-[4px] focus:outline-grey-100',
        default: [
          'bg-primary hover:bg-hover text-white rounded-full',
          'focus:outline focus:outline-[4px] focus:outline-grey-100',
          'active:bg-active active:outline-none',
          'disabled:pointer-events-none disabled:bg-grey-300 disabled:text-gray-400 disabled:hover:bg-grey-300 disabled:shadow-none',
        ],
        outline: [
          'bg-content shadow-border hover:bg-grey-300 text-primary rounded-full',
          'focus:outline focus:outline-[4px] focus:outline-grey-100',
          'active:bg-grey-500 active:outline-none',
          'disabled:pointer-events-none disabled:bg-grey-300 disabled:text-gray-400 disabled:hover:bg-grey-300 disabled:shadow-none',
        ],
        icon: [
          'bg-transparent rounded-full hover:bg-black/5 p-0.5',
          'focus:outline-none focus:bg-black/5',
          'active:[&>svg]:scale-95 transition-transform active:outline-none',
        ],
        'outline-icon': [
          'bg-transparent rounded-full hover:bg-transparent',
          'focus:outline focus:outline-[2px] focus:outline-grey-100',
        ],
        square:
          'bg-primary hover:bg-hover text-white rounded focus:outline focus:outline-[4px] focus:outline-grey-100 active:bg-active active:outline-none disabled:pointer-events-none disabled:bg-grey-300 disabled:text-gray-400 disabled:hover:bg-grey-300 disabled:shadow-none',
        'square-blue': [
          'bg-[#5375B4] hover:bg-[#5e7aaf] text-white rounded',
          'focus:outline focus:outline-[4px] focus:outline-grey-100',
          'disabled:pointer-events-none disabled:bg-grey-300 disabled:text-gray-400 disabled:hover:bg-grey-300 disabled:shadow-none',
        ],
        'square-gray':
          'bg-link-button text-link-button-text rounded focus:outline focus:outline-[4px] focus:outline-gray-200 active:outline-none disabled:pointer-events-none disabled:bg-grey-300 disabled:text-gray-400 disabled:hover:bg-grey-300 disabled:shadow-none',
        'square-outline': [
          'bg-content shadow-border hover:bg-grey-300 text-primary rounded',
          'focus:outline focus:outline-[4px] focus:outline-grey-100',
          'active:bg-grey-500 active:outline-none',
          'disabled:pointer-events-none disabled:bg-grey-300 disabled:text-gray-400 disabled:hover:bg-grey-300 disabled:shadow-none',
        ],
        prompt: [
          'bg-grey-200 shadow-border-prompt hover:bg-content text-primary rounded',
          'focus:outline focus:outline-[4px] focus:outline-grey-100',
          'active:bg-grey-500 active:outline-none',
        ],
        radio: [
          'bg-grey-200 hover:bg-content text-primary rounded-lg shadow-icon outline-none',
          'focus:shadow-input-focus',
          'active:bg-grey-500 focus:active:bg-grey-500 focus:active:hover:bg-grey-500 focus:hover:bg-grey-500',
        ],
        ghost: 'hover:bg-accent hover:text-accent-foreground',
      },
      size: {
        icon: 'p-0',
        iconLarge: 'p-3',
        iconPrompt: 'p-2.5',
        link: 'px-6 h-12 text-sm tracking-wide',
        md: 'px-4 py-2.5',
        lg: 'px-6 py-3',
        sm: 'text-xs px-4 py-2.5 font-normal uppercase tracking-[0.48px] leading-[18px]',
      },
      disabled: {
        true: 'bg-grey-300 text-gray-400 hover:bg-grey-300 pointer-events-none border-grey-300',
        false: 'cursor-pointer',
      },
      loading: {
        true: 'pointer-events-none opacity-90',
      },
    },
    defaultVariants: {
      size: 'md',
      variant: 'default',
    },
    compoundVariants: [
      {
        disabled: true,
        loading: true,
        variant: ['square', 'default'],
        className: 'bg-primary text-white border-none',
      },
    ],
  },
)
