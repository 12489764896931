import { useAuth } from '@/app/_auth/auth-hooks'
import { Domain } from '@/app/_auth/auth-provider'
import { SideNavigation } from '@/app/_common/side-navigation'
import { NotFoundPage } from '@/app/not-found'
import { Toaster } from '@/components/ui/toaster'
import { useQueryClient } from '@tanstack/react-query'
import { createRootRoute, Outlet, retainSearchParams, useSearch } from '@tanstack/react-router'
import React from 'react'

type SearchParams = {
  prompt?: string
  title?: string
  domain?: Domain
}

const RootRoute = () => {
  const { setDomain, domain } = useAuth()
  const search = useSearch({ from: '__root__' })
  const queryClient = useQueryClient()

  React.useEffect(() => {
    if (search.domain && search.domain !== domain) {
      setDomain(search.domain)
    }
  }, [search.domain])

  React.useEffect(() => {
    if (domain === search.domain) {
      queryClient.cancelQueries().then(() => queryClient.refetchQueries())
    }
  }, [domain])

  return (
    <main className='relative flex h-[100dvh] overflow-y-clip font-circular-xx text-[#252539]'>
      <SideNavigation />
      <Outlet />
      <Toaster />
    </main>
  )
}

export const Route = createRootRoute({
  notFoundComponent: NotFoundPage,
  validateSearch: () => ({}) as SearchParams,
  search: {
    middlewares: [retainSearchParams(['domain'])],
  },
  component: RootRoute,
})
