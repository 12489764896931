import { toast } from '@/components/ui/toaster'
import { type ClassValue, clsx } from 'clsx'
import copyToClipboard from 'copy-to-clipboard'
import dayjs from 'dayjs'
import DurationPlugin from 'dayjs/plugin/duration'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import type { AnyObject, ValidationErrors } from 'final-form'
import { twMerge } from 'tailwind-merge'

const isVPCMode = import.meta.env.MODE === 'vpc'

/**
 * A shorthand for {@link twMerge} that also uses {@link clsx} internally.
 *
 * @param inputs - The class names to be merged.
 * @returns The merged class names.
 *
 * @example
 * cn('px-4', 'text-lg') // => 'px-4 text-lg'
 */
function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

/**
 * Copies the provided text to the clipboard and displays a toast notification.
 *
 * @param {string} [params.text] - The text to be copied to the clipboard.
 * @param {string} params.errorMessage - The message to display if copying fails.
 * @param {string} params.successMessage - The message to display if copying succeeds.
 */
function copy({
  text,
  errorMessage,
  successMessage,
}: {
  text?: string
  errorMessage: string
  successMessage: string
}) {
  const success = !!text && copyToClipboard(text)

  if (success) toast.success(successMessage)
  else toast.error(errorMessage)
}

// 👇 date utils

dayjs.extend(DurationPlugin)
dayjs.extend(LocalizedFormat)

/**
 * Formats the given date as a string.
 *
 * @param {number|string} date - A date in seconds since the Unix epoch, or a string that can be parsed
 *   into one.
 * @param {Object} [options] - Options for formatting the date.
 * @param {string} [options.template='LL'] - A {@link https://day.js.org/docs/en/display/format|dayjs date formatting template}.
 * @returns {string} The formatted date string.
 */
function formatDate(date: number | string, options: { template?: string } = { template: 'LL' }) {
  const dateNumber = typeof date === 'string' ? parseInt(date) : date

  return dayjs(dateNumber * 1000).format(options.template)
}

/**
 * Calculates the duration between two epoch timestamps and returns it as a formatted string.
 *
 * @param {Object} params - The parameters for the function.
 * @param {number} [params.startEpoch] - The starting epoch time in seconds.
 * @param {number} [params.endEpoch] - The ending epoch time in seconds.
 * @returns {string} The duration formatted as "HH:mm:ss". Returns "00:00:00" if either epoch is not provided.
 */

function getDateDiffDuration({ startEpoch, endEpoch }: { startEpoch?: number; endEpoch?: number }) {
  if (!startEpoch || !endEpoch) return '00:00:00'

  const diff = dayjs(endEpoch * 1000).diff(startEpoch * 1000)

  return dayjs.duration(diff).format('HH:mm:ss')
}

// 👇 form utils
function trimFormValues<TValues extends AnyObject>(values: TValues): TValues {
  const entries: [keyof TValues, any][] = Object.entries(values)

  return entries.reduce(
    (result, [key, value]) => ({
      ...result,
      [key]: typeof value === 'string' ? value.trim() : value,
    }),
    {} as TValues,
  )
}

function trimAndValidate<TValues extends AnyObject>({
  initialValues,
  validate,
}: {
  initialValues: TValues
  validate: (values: TValues) => ValidationErrors
}): { values: TValues; errors?: ValidationErrors } {
  const values = trimFormValues(initialValues)
  const errors = validate(values)

  if (errors && Object.keys(errors).length > 0) {
    return { values, errors }
  }

  return { values }
}

// 👇 Google Analytics event data

const GA_EVENTS = {
  CATEGORY: {
    NAV: 'navigation',
    ACC: 'account_settings',
    EMAIL: 'email_form',
    STRIPE: 'stripe_purchase',
    HUBSPOT: 'hubspot_form',
  },
  ACTION: {
    BTN: 'button_click',
  },
} as const

export {
  cn,
  copy,
  formatDate,
  GA_EVENTS,
  getDateDiffDuration,
  isVPCMode,
  trimAndValidate,
  trimFormValues,
}
