import { inputVariants } from '@/components/ui/input'
import { cn } from '@/lib/utils'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'
import TextareaAutoSizeComp, {
  type TextareaAutosizeProps as TTextareaAutosizeProps,
} from 'react-textarea-autosize'

type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & TextAreaVariants
const Textarea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, variant, error, ...props }, ref) => (
    <textarea
      tabIndex={0}
      className={cn(textAreaVariants({ variant, error }), className)}
      ref={ref}
      {...props}
    />
  ),
)
Textarea.displayName = 'Textarea'

type TextareaAutosizeProps = TTextareaAutosizeProps & (TextAreaVariants & { unstyled?: boolean })
const TextareaAutoSize = React.forwardRef<HTMLTextAreaElement, TextareaAutosizeProps>(
  ({ className, variant, error, unstyled, ...props }, ref) => (
    <TextareaAutoSizeComp
      tabIndex={0}
      className={cn(!unstyled && textAreaVariants({ variant, error }), className)}
      ref={ref}
      {...props}
    />
  ),
)
TextareaAutoSize.displayName = 'TextareaAutoSize'

type TextAreaVariants = VariantProps<typeof textAreaVariants>
const textAreaVariants = cva('', {
  variants: {
    variant: {
      input: [inputVariants({ height: 'auto', text: 'md' }), 'px-3 py-2'],
      gray: [inputVariants({ variant: 'gray', height: 'auto', text: 'md' }), 'px-3 py-2'],
      inputError: [inputVariants({ error: true, height: 'auto', text: 'md' }), 'px-3 py-2'],
      prompt:
        'bg-[#EEEEEF] resize-none p-4 text-base w-full outline-none font-normal rounded-xl disabled:pointer-events-none disabled:text-[#AEA9B4]',
    },
    error: {
      true: inputVariants({ error: true, variant: 'gray', height: 'auto', text: 'md' }),
    },
  },
  defaultVariants: {
    variant: 'input',
  },
  compoundVariants: [
    { variant: 'gray', error: false, className: 'border-[#AEA9B4] focus:outline-focus' },
  ],
})

export { Textarea, TextareaAutoSize, type TextareaAutosizeProps, type TextAreaProps }
